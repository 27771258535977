import { Component, OnInit } from "@angular/core";
import {
  faClosedCaptioning,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { Element_ } from "../Element_";
import { MediaType } from "../../../../app/models/elements/Enums";
import { MediaModel } from "../../../models/elements/MediaModel";
import { LocalStorageService } from "src/app/providers/local-storage/local-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.scss"],
  host: { "[class.banner]": "this.model.banner" },
})
export class Media extends Element_ implements OnInit {
  faIcon: IconDefinition;
  model: MediaModel;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
  ) {
    super();
    this.model = this.model as MediaModel;
  }

  ngOnInit() {
    if (MediaType.isExcel(this.model.type)) {
      this.faIcon = faFileExcel;
    } else if (MediaType.isWord(this.model.type)) {
      this.faIcon = faFileWord;
    } else if (MediaType.isPdf(this.model.type)) {
      this.faIcon = faFilePdf;
    } else if (MediaType.isPowerpoint(this.model.type)) {
      this.faIcon = faFilePowerpoint;
    } else if (MediaType.isWebVTT(this.model.type)) {
      this.faIcon = faClosedCaptioning;
    }
  }

  startPlayer(event: Event) {
    const players = document.getElementsByClassName("mediaplayer");
    const currentPlayer = event.currentTarget;
    for (let i = 0; i < players.length; i++) {
      const player = players[i] as HTMLMediaElement;
      if (player !== currentPlayer) {
        player.pause();
      }
    }
  }

  getSubtitlesLanguage(sourceLanguage: string): string {
    switch (sourceLanguage) {
      case "de":
        return this.translateService.instant("GENERAL.GERMAN");
      case "en":
        return this.translateService.instant("GENERAL.ENGLISH");
      case "fr":
        return this.translateService.instant("GENERAL.FRENCH");
      case "es":
        return this.translateService.instant("GENERAL.SPANISH");
      case "ca":
        return this.translateService.instant("GENERAL.CATALAN");
      case "ro":
        return this.translateService.instant("GENERAL.ROMANIAN");
      default:
        return sourceLanguage;
    }
  }

  get language(): string {
    return this.localStorage.getAppLanguage();
  }

  get MediaType() {
    return MediaType;
  }
}
