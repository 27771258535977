<div class="question-media question ion-text-center" [class.banner]="model.banner">
  <div class="header-line" [class.banner-headline]="model.banner">
    <h4 class="ion-text-left">
      {{ model.subtitleResolved }}
    </h4>
    @if (!MediaType.isImage(model.type)) {
      <file-downloader
        [downloadUrl]="model.webUri"
        [clearFileName]="model.filename"
        downloadMode="IndirectDownload"
      ></file-downloader>
    }
  </div>
  @if (MediaType.isImage(model.type)) {
    <img
      [src]="model.webUri"
      alt="Picture not available. Please try again."
      [style.width.%]="model.width"
    >
  } @else if (MediaType.isVideo(model.type)) {
    @if (!model.isAudio) {
      <div class="ion-text-left">
        <video
          controls
          class="mediaplayer"
          [style.width.%]="model.width"
          crossorigin="anonymous"
          controlsList="nodownload"
          (play)="startPlayer($event)"
        >
          <source [src]="model.webUri" type="video/mp4" />
          @if (model.webUri && model.videoSubtitleUri) {
            <track
              [src]="model.videoSubtitleUri"
              kind="subtitles"
              [label]="getSubtitlesLanguage(language)"
              [srclang]="language"
              default
            />
          }
        </video>
      </div>
    } @else {
      <div class="audioDiv">
        <audio
          [src]="model.webUri"
          controls
          class="mediaplayer"
          controlsList="nodownload"
          (play)="startPlayer($event)"
        ></audio>
      </div>
    }
  } @else if (MediaType.isAudio(model.type)) {
    <div class="audioDiv">
      <audio
        [src]="model.webUri"
        controls
        class="mediaplayer"
        controlsList="nodownload"
        (play)="startPlayer($event)"
      ></audio>
    </div>
  } @else if (MediaType.isApplication(model.type) || MediaType.isWebVTT(model.type)) {
    <file-downloader
      openAfterDownload="true"
      downloadMode="DirectDownload"
      [faIcon]="faIcon"
      [downloadUrl]="model.webUri"
      [clearFileName]="model.filename"
    ></file-downloader>
  }

  @if (model.description != "") {
    <div [class.banner-headline]="model.banner">
      <p class="ion-text-left">
        {{ model.descriptionResolved }}
      </p>
    </div>
  }
</div>
