import { Component, Input, OnInit } from "@angular/core";
import { ElementModel } from "src/app/models/elements/ElementModel";

var evaluationService = require("@aas2/aas2-evaluation-service");

@Component({
  selector: "app-element",
  templateUrl: "./element.component.html",
  styleUrls: ["./element.component.scss"],
})
export class Element implements OnInit {
  public model: ElementModel;
  public type: string;
  @Input() public loopIndex: number;

  constructor() {}

  ngOnInit() {
    if (this.model.lesson.answers) {
      //for lesson resolve references
      this.model.lesson.answers.subscribe((_x) => {
        for (const attr of this.model.resolvable) {
          const attrResolved = attr + "Resolved";
          if (attrResolved === "optionsResolved") {
            //solve references for each option entry
            const resolvedOptions = [];
            for (const entry in this[attr]) {
              resolvedOptions[entry] = evaluationService.evaluateReferences(
                this[attr][entry],
                this.model.lesson._answers,
                this.model.lesson.labelList,
                this.model.lesson.localStorage.getAppLanguage(),
              );
            }
            this[attrResolved] = resolvedOptions;
          } else {
            this[attrResolved] = evaluationService.evaluateReferences(
              this[attr],
              this.model.lesson._answers,
              this.model.lesson.labelList,
              this.model.lesson.localStorage.getAppLanguage(),
            );
          }
        }
      });
    } else {
      //for skills use normal attribute (there are no references in skills)
      for (const attr of this.model.resolvable) {
        const attrResolved = attr + "Resolved";
        this[attrResolved] = this[attr];
      }
    }
  }

  @Input() set init(element: ElementModel) {
    this.model = element;
    this.type = element.constructor.name;
  }
}
