@if (model) {
  <div [attr.id]="'element' + model.position + '-' + loopIndex">
    @switch (type) {
      @case ("HeadlineModel") {
        <app-headline [init]="model"></app-headline>
      }
      @case ("TextModel") {
        <app-text [init]="model"></app-text>
      }
      @case ("MediaModel") {
        <app-media [init]="model"></app-media>
      }
      @case ("QuestionYesNoModel") {
        <app-yes-no [init]="model" [loopIndex]="loopIndex"></app-yes-no>
      }
      @case ("QuestionSingleChoiceModel") {
        <app-single-choice [init]="model" [loopIndex]="loopIndex"></app-single-choice>
      }
      @case ("QuestionMultipleChoiceModel") {
        <app-multiple-choice [init]="model" [loopIndex]="loopIndex"></app-multiple-choice>
      }
      @case ("QuestionSliderModel") {
        <app-slider [init]="model" [loopIndex]="loopIndex"></app-slider>
      }
      @case ("QuestionDateModel") {
        <app-text-date [init]="model" [loopIndex]="loopIndex"></app-text-date>
      }
      @case ("QuestionTextStringModel") {
        <app-text-string [init]="model" [loopIndex]="loopIndex"></app-text-string>
      }
      @case ("QuestionTextAreaModel") {
        <app-text-area [init]="model" [loopIndex]="loopIndex"></app-text-area>
      }
      @case ("QuestionTableModel") {
        <app-questiontable [init]="model" [loopIndex]="loopIndex"></app-questiontable>
      }
      @case ("LineModel") {
        <app-line [init]="model"></app-line>
      }
      @case ("SpaceModel") {
        <app-space [init]="model"></app-space>
      }
      @case ("BlockModel") {
        <app-block [init]="model" [loopIndex]="loopIndex"></app-block>
      }
    }
  </div>
}
